var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "online-schema-form" }, [
        _vm.form
          ? _c(
              "form",
              { attrs: { action: "" } },
              [
                _vm.showSelectText
                  ? _c("label", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        "\n      Select the box that applies to your company:\n    "
                      ),
                    ])
                  : _vm._e(),
                _c("field-list", {
                  key: _vm.renderKey,
                  attrs: {
                    fields: _vm.currentFields,
                    "contextual-jurisdiction": _vm.contextualJurisdiction,
                    "registered-agent-field": _vm.registeredAgentField,
                  },
                  on: {
                    "suggestion-toggled": function ($event) {
                      return _vm.$emit("suggestion-toggled", $event)
                    },
                    "show-contact-modal": function ($event) {
                      return _vm.$emit("show-contact-modal")
                    },
                    input: _vm.valuesUpdated,
                    "ra-signup": function ($event) {
                      return _vm.$emit("ra-signup")
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "buttons-div" },
          [
            _c(
              "b-button",
              {
                staticClass: "button-back",
                attrs: {
                  variant: "default",
                  "aria-label": "back button",
                  disabled: _vm.disablePreviousButton,
                },
                on: { click: _vm.navigatePrevious },
              },
              [
                _c("fa-icon", {
                  staticClass: "fa-xs",
                  attrs: { icon: "chevron-left" },
                }),
                _vm._v(" Back\n    "),
              ],
              1
            ),
            _c(
              "b-button",
              {
                staticClass: "button-next",
                attrs: { variant: "primary", "aria-label": "continue button" },
                on: { click: _vm.navigateNext },
              },
              [
                _c("span", { staticClass: "px-3" }, [
                  _vm._v("\n        Continue\n      "),
                ]),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }