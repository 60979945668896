<template>
  <div v-if="loaded" class="online-schema-form">
    <form v-if="form" action="">
      <label v-if="showSelectText" class="font-weight-bold">
        Select the box that applies to your company:
      </label>

      <field-list
        :key="renderKey"
        :fields="currentFields"
        :contextual-jurisdiction="contextualJurisdiction"
        :registered-agent-field="registeredAgentField"
        @suggestion-toggled="$emit('suggestion-toggled', $event)"
        @show-contact-modal="$emit('show-contact-modal')"
        @input="valuesUpdated"
        @ra-signup="$emit('ra-signup')"
      />
    </form>

    <div class="buttons-div">
      <b-button
        class="button-back"
        variant="default"
        aria-label="back button"
        :disabled="disablePreviousButton"
        @click="navigatePrevious"
      >
        <fa-icon icon="chevron-left" class="fa-xs" /> Back
      </b-button>
      <b-button
        class="button-next"
        variant="primary"
        aria-label="continue button"
        @click="navigateNext"
      >
        <span class="px-3">
          Continue
        </span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { SchemaForm } from '@/components/SchemaForm/form'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OnlineSchemaForm',
  components: {
    FieldList: () => import('@/components/SchemaForm/fields/SchemaFormFieldList'),
  },
  props: {
    fields: [Object, Array],
    value: Object,
    suggestionFields: [Object, Array],
    saveSuccessful: Boolean,
    contextualJurisdiction: Object,
  },
  data () {
    return {
      loaded: false,
      form: null,
      renderKey: 0,
    }
  },
  computed: {
    ...mapGetters('schema', ['currentObject']),
    ...mapGetters('stageline', [
      'currentField',
      'startingField',
      'processFilingObjects',
      'currentObjectId',
      'objectsRequiringAttention',
      'currentSlide',
    ]),
    visibleFields() {
      // For fields that were already answered in the pdf viewer,
      // they are filtered out in the MissingDataSlide -> filterOutFieldsAlreadyAnsweredInPdf method
      // by setting the type to hidden and isVisible to false
      return this.form.children.filter(c => !!c.isVisible && !('clonedFrom' in c) && c.type !== 'disclaimer' && !this.fieldAlreadyCompleted(c.name))
    },
    currentFields() {
      return this.form.children.filter(f => f.name === this.currentField || f.name.includes(this.currentField + '_'))
    },
    fieldsAlreadyCompleted() {
      // Used to filter out fields that were already filled out in an existing order item
      const fields = this.processFilingObjects && this.processFilingObjects.filter(o => o.object_id != this.currentObjectId).map(({ fields }) => fields) || []
      const mergedFields = [].concat.apply([], fields)
      return mergedFields
    },
    showSelectText() {
      return this.currentFields.length && this.currentFields[0]?.type === 'boolean'
    },
    isFirstField() {
      return (this.visibleFields[0]?.name === this.currentField)
    },
    isLastField() {
      return (this.visibleFields[this.visibleFields.length - 1].name === this.currentField)
    },
    isFirstObject() {
      return (this.currentObject.type === 'shopping-cart-filing' ||
        (this.objectsRequiringAttention[0]?.object_id === this.currentObject.id))
    },
    isLastObject() {
      return (this.currentObject.type === 'shopping-cart-filing' ||
        (this.objectsRequiringAttention[this.objectsRequiringAttention.length - 1]?.object_id === this.currentObject.id))
    },
    disablePreviousButton() {
      return this.isFirstField && this.isFirstObject && this.currentObject.type !== 'shopping-cart-filing'
    },
    registeredAgentField() {
      return this.form.children.find(field => field.name === 'registered_agent')
    },
  },
  async mounted() {
    this.initializeSchemaForm()
    if (!this.visibleFields.length) this.$emit('no-visible-fields')
  },
  beforeDestroy() {
    if (this.field) {
      this.field.onChildAddedOrRemoved = null
    }
  },
  methods: {
    ...mapActions('stageline', [
      'goToPreviousSlide',
    ]),
    initializeSchemaForm() {
      this.loaded = false
      this.loadFormData()
      this.setResourceField('current')
      this.loaded = true
    },
    fieldAlreadyCompleted (fieldName) {
      return this.fieldsAlreadyCompleted.includes(fieldName)
    },
    loadFormData() {
      this.appendSuggestionValues()
      this.form = new SchemaForm(this.fields, this.value)

      this.form.onChildAddedOrRemoved = () => {
        this.$emit('input', this.form.values)
      }
    },
    setResourceField(type) {
      const field = this.determineResourceField(type)
      this.$emit('set-current-field', field)
    },
    determineResourceField(type) {
      let field = this.currentField && this.currentFields ? this.currentField : this.visibleFields[0]?.name
      if (!field) return
      if (this.startingField === 'first') {
        field = this.visibleFields[0].name
      } else if (this.startingField === 'last') {
        field = this.visibleFields[this.visibleFields.length - 1].name
      } else if (this.currentField) {
        const currentIndex = this.visibleFields.findIndex(f => f.name === this.currentField)
        const validIndex = currentIndex != -1

        if (validIndex && type === 'previous') {
          field = this.visibleFields[currentIndex -1].name
        } else if (validIndex && type === 'next') {
          field = this.visibleFields[currentIndex + 1].name
        }
      }
      return field
    },
    valuesUpdated(ev) {
      this.form.set(ev.path, ev.value)
      this.$emit('input', this.form.values)
    },
    appendSuggestionValues() {
      if (this.suggestionFields) {
        this.fields.forEach(f => {
          if (this.suggestionFields[f.name]) f.useHint = true
          else if (f.useHint) f.useHint = false
        })
      }
      ++this.renderKey
    },

    async navigateNext() {
      this.loaded = false
      const navigateTo = { field: null, object: null }

      if (this.currentField && this.isLastField) {
        if (this.isLastObject) {
          if (this.currentObject.type === 'shopping-cart-filing') {
            this.$emit('save')
          } else {
            navigateTo.field = 'review'
          }
        } else {
          navigateTo.object = 'next'
        }
      } else {
        navigateTo.field = this.determineResourceField('next')
      }

      this.$emit('navigate', navigateTo)
      this.loaded = true
    },

    async navigatePrevious() {
      this.loaded = false
      const navigateTo = { field: null, object: 'previous' }

      if (!(this.currentField && this.isFirstField)) {
        navigateTo.field = this.determineResourceField('previous')
      } else if (this.isFirstField && this.isFirstObject && this.currentObject.type === 'shopping-cart-filing') {
        this.goToPreviousSlide()
      }

      this.$emit('navigate', navigateTo)
      this.loaded = true
    },
  },
}
</script>

<style lang="scss" scoped>

.buttons-div {
  float: right;

  .button-back {
    color: $ct-ui-primary;

    &:hover {
      background: $ct-ui-primary-light;
    }
  }

  .button-next {
    background: $ct-ui-primary;
    border-color: $ct-ui-primary;

    &:hover {
      background: $ct-ui-primary-dark;
      border-color: $ct-ui-primary;
    }
  }
}
</style>
